:local {
    .homeSiteLink {
        width: 23%;
        height: 3%;
        position: absolute !important;
        left: 5%;
        bottom: 12%;
        cursor: pointer;
    }

    .sandboxLink {
        width: 21%;
        height: 41%;
        position: absolute !important;
        right: 2%;
        bottom: 4%;
        cursor: pointer;
        background-color: red;

        background-image: url('./images/custom-button.jpg');
        background-position: center;
        background-size: 100%;
        border-radius: 10px;
        overflow: hidden;
        transform: scale(1);
        transition: all 0.2s ease !important;

        &:hover {
            transform: scale(1.025);
        }
    }
}