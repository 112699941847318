:local {
    .slide {
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        display: block;
        width: 1960px;
        height: 1080px;
        margin: 0 !important;
        padding: 0 !important;
    }
}

