
#root {
	display: flex;
    width: 100%;
    height: 100%;
}

.swiper-slide {
    width: auto;
    height: auto;
}

.progress span {
    color: #EC1B2E;
    background-color: #EC1B2E;
}
